import { render, staticRenderFns } from "./case-collection-distribution.vue?vue&type=template&id=2c83b260&scoped=true"
import script from "./case-collection-distribution.vue?vue&type=script&lang=ts"
export * from "./case-collection-distribution.vue?vue&type=script&lang=ts"
import style0 from "./case-collection-distribution.vue?vue&type=style&index=0&id=2c83b260&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c83b260",
  null
  
)

export default component.exports